import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '1, 87, 155',
		'primary-dark': '0, 47, 108',
		'accent': '255, 109, 0',
		'accent-plus': '255, 255, 255',
	},
});
